import {
  type Blot,
  type BlotConstructor,
  blotNodeMap,
  type DeltaInsertAttributes,
  descendantBlot,
  descendantBlots,
  CalcOptions,
  type EditorOptions, getNumberingMaskVersion, updateOldNumberingSystem
} from '@avvoka/editor'
import {
  blotList,
  Commands,
  GenerateStyleEntity,
  generateStylesSystem,
  isScopeExactLineBlot,
  type Numbered,
  type ListNum,
  type ParentBlot,
  Query,
  Registry,
  ScheduledBlot,
  ScheduleType,
  Scroll,
  type Stages,
  SystemOptions,
  SystemTime,
  updateNumberingSystem,
  updateStylesSystem
} from '@avvoka/editor'
import {getCurrentLocale, useLocalizationStore} from "@stores/features/localization.store";
import type { CustomClauseVariantStoreType } from '@stores/generic/customClauseVariant.store'
import {getActivePinia} from "pinia";
import {type DocumentStoreType} from '../../../entrypoints/stores/document'
import {type TemplateVersionStoreType} from '@stores/generic/templateVersion.store'
import { onStorePropertiesHydrated, StoreMode } from '@stores/utils'
import { watch } from 'vue'

interface CustomGenerateStyleEntity {
  style: string
  custom?: boolean
}

const styleMap = new Map<string, HTMLElement>()
const lineFormats = blotList.filter(isScopeExactLineBlot).map((b) => b.tagName)

/**
 * Returns a measurement function to convert pixels (px) to millimeters (mm).
 */
const getMeasurementNode = () => {
  const ratioNode = document.createElement('div')
  ratioNode.style.display = 'none'
  ratioNode.style.height = '100mm'
  ratioNode.classList.add('px-to-mm-ratio')
  document.body.appendChild(ratioNode)

  return {
    PX_TO_MM: (px: number) =>
      px / (parseFloat(window.getComputedStyle(ratioNode).height) / 100)
  }
}

const getEditorOptionsMockup = async () => {
  const mode = EditorFactory.mainOptional.mapOr(
    (editor) => editor.options.mode,
    'document'
  )
  const rtl = false

  const locale = await getCurrentLocale();
  const localizationStore = useLocalizationStore(getActivePinia())
  await localizationStore.hydrate({
    locale: locale
  }, ['localized_numbers'])

  return { mode, rtl, localizedNumbers: localizationStore.localizedNumbers } as unknown as EditorOptions
}

const getStagesMockup = () => {
  return { schedule() {} } as unknown as Stages
}

/**
 * Create a mockup of a Blot object based on the given element and parent.
 * The blot will be created with all attributes and children.
 * Use this to generate styles for a given element in recalculating styles.
 *
 * @param element - The HTML element to create a mockup of.
 * @param editorOptions - Editor Options
 * @param parent - The parent blot of the element.
 * @returns - The created Blot mockup, or null if no matching blot class found.
 */
const createBlotMockup = (
  element: HTMLElement,
  editorOptions: EditorOptions,
  parent?: ParentBlot,
): Blot | null => {
  const fixAttributeName = (name: string) => {
    if (name === 'pattern') return 'data-mask-pattern'
    return name
  }

  let nodeName = element.nodeName
  if (nodeName === '#text') nodeName = 'TEXT'
  if (nodeName === '#comment')
    nodeName = element.nodeValue?.split(' ')[0].toUpperCase() || 'COMMENT'

  const blotClass = blotNodeMap.get(nodeName)
  if (blotClass.isSome()) {
    const constr = blotClass.get() as unknown as BlotConstructor
    const blot = new constr(
      element as unknown as Node,
      editorOptions
    )
    Array.from(element.attributes ?? []).forEach((val) =>
      blot.setDirectAttribute(
        fixAttributeName(val.nodeName),
        val.nodeValue,
        true
      )
    )
    blot.parent = parent

    // parse all children
    if ('children' in blot) {
      const children = Array.from(element.childNodes ?? []).map((child) =>
        createBlotMockup(child as HTMLElement, editorOptions, blot as ParentBlot)
      )
      blot.children = children.filter((child): child is Blot => child !== null)
    }

    return blot
  }

  return null
}

const createBlotsMockup = (formats: DeltaInsertAttributes, editorOptions: EditorOptions): Blot[] => {
  const blots = Object.keys(formats)
    .map((blotName) => {
      const blot = blotList.find((b) => b.blotName === blotName)
      if (!blot) {
        console.warn(
          `The blot ${blotName} was not found. Please check the updates are not running otherwise this is a bug.`
        )
        return null
      }

      const node = document.createElement(blot.tagName)
      Object.entries(formats[blot.blotName]).forEach(([key, value]) => {
        node.setAttribute(`${key}`, String(value))
      })

      return createBlotMockup(node, editorOptions)
    })

  return blots.filter((result): result is Blot => result !== null)
}

const createStyleEntitiesForBlots = (blots: Blot[], commands: Commands) => {
  const getTextTransform = (value: string) => {
    if (value === 'upper') return 'uppercase'
    if (value === 'lower') return 'lowercase'
    if (value === 'capital') return 'capitalize'
    return 'unset'
  }

  const getFontVariant = (value: string) => {
    if (value === 'small-caps') return 'small-caps'
    return 'unset'
  }

  const getFontFamily = (value: string): string => {
    switch (value) {
      case 'arial':
        return `Arial, Helvetica, sans-serif;`
      case 'arial-black':
        return `"Arial Black", Gadget, sans-serif;`
      case 'calibri':
        return `Calibri, Carlito, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;`
      case 'comic-sans-ms':
        return `"Comic Sans MS", cursive, sans-serif;`
      case 'courier-new':
        return `"Courier New", Courier, monospace;`
      case 'georgia':
        return `Georgia, serif;`
      case 'impact':
        return `Impact, Charcoal, sans-serif;`
      case 'lucida-console':
        return `"Lucida Console", Monaco, monospace;`
      case 'lucida-sans-unicode':
        return `"Lucida Sans Unicode", "Lucida Grande", sans-serif;`
      case 'palatino-linotype':
        return `"Palatino Linotype", FreeSerif, "Book Antiqua", Palatino, serif;`
      case 'tahoma':
        return `Tahoma, Kalimati, Geneva, sans-serif;`
      case 'times-new-roman':
        return `"Times New Roman", Times, serif;`
      case 'trebuchet-ms':
        return `"Trebuchet MS", Helvetica, sans-serif;`
      case 'verdana':
        return `Verdana, Geneva, sans-serif;`
    }
    if (value) return value + ', Arial, Helvetica, sans-serif;'
    return getFontFamily('arial')
  }

  blots.forEach((blot) => {
    switch (blot.statics.blotName) {
      case 'color':
        commands.spawn({ style: `{color: #${blot.attributes['data-value']};}` })
        break
      case 'bold':
        commands.spawn({ style: `{font-weight: bold;}` })
        break
      case 'italic':
        commands.spawn({ style: `{font-style: italic;}` })
        break
      case 'underline':
        commands.spawn({ style: `{text-decoration: underline;}` })
        break
      case 'sub':
        commands.spawn({ style: `{vertical-align: sub;font-size: .83em;}` })
        break
      case 'sup':
        commands.spawn({ style: `{vertical-align: super;font-size: .83em;}` })
        break
      case 'textTransform':
        commands.spawn({
          style: `{text-transform: ${getTextTransform(
            blot.attributes['data-value']
          )}; font-variant: ${getFontVariant(blot.attributes['data-value'])};}`
        })
        break
      case 'font':
        commands.spawn({
          style: `{font-family: ${getFontFamily(blot.attributes['font'])}}`
        })
        break
      case 'fontSize':
        commands.spawn({ style: `{font-size: ${+blot.attributes['size']}pt;}` })
        commands.spawn({
          style: `avv-numbered:has($IDENT$)::before {font-size: ${+blot
            .attributes['size']}pt;}`,
          custom: true
        })
        break
      case 'block':
        {
          const ext = (val: string) =>
            blot.attributes[val] ? blot.attributes[val] + 'px' : 'unset'
          // Extract margins from blot.attributes and join them into a string for css margin property
          commands.spawn(
            new GenerateStyleEntity(
              `{margin-top: ${ext('data-avv-margin-top')}; margin-right: ${ext(
                'data-avv-margin-right'
              )}; margin-bottom: ${ext(
                'data-avv-margin-bottom'
              )}; margin-left: ${ext('data-avv-margin-left')};}`,
              blot
            )
          )
        }
        break
    }
  })
}

const transformStyleEntitiesIntoGlobalStyles = (
  styleName: string,
  styleEntities: CustomGenerateStyleEntity[],
  defaultStyleKey: string,
  numberings: Numbered[]
) => {
  // Generate the style element if it doesn't exist
  let styleElement = styleMap.get(styleName)
  if (!styleElement) {
    styleElement = document.createElement('style')
    styleElement.setAttribute('generated', styleName)
    document.head.appendChild(styleElement)
    styleMap.set(styleName, styleElement)
  }

  const serializeName = (name: string) => {
    return name.replace(/[\[\]]/g, '\\$1')
  }

  const styleIdentifier = `${lineFormats
    .flatMap((tag) => {
      if (styleName === defaultStyleKey) {
        return [
          `.avv-editor .avv-container ${tag}:not([data-avv-style])`,
          `.avv-editor .avv-container ${tag}[data-avv-style="${serializeName(
            styleName
          )}"]`
        ]
      } else {
        return `.avv-editor .avv-container ${tag}[data-avv-style="${serializeName(
          styleName
        )}"]`
      }
    })
    .join(', ')}, .avv-styles\\:${serializeName(styleName)} `

  styleElement.innerHTML = `${styleIdentifier} { ${styleEntities.reduce(
    (result, { style, custom }) => {
      if (custom) return result
      return (
        result +
        style
          .substring(style.indexOf('{') + 1, style.lastIndexOf('}'))
          .replace(' !important', '') +
        '\n'
      )
    },
    ''
  )} }`

  // Map the lines to css selectors with the given style name
  const innerIdent = lineFormats
    .flatMap((line) => {
      if (styleName === defaultStyleKey) {
        return [
          `${line}:not([data-avv-style])`,
          `${line}[data-avv-style="${styleName}"]`
        ]
      } else {
        return `${line}[data-avv-style="${styleName}"]`
      }
    })
    .join(', ')

  if (styleName === defaultStyleKey) {
    const fontSizeEntity = styleEntities.find((e) =>
      e.style.startsWith('{font-size:')
    )
    if (fontSizeEntity) {
      const fontSize = fontSizeEntity.style.substring(
        fontSizeEntity.style.indexOf('{') + 1,
        fontSizeEntity.style.lastIndexOf('}')
      )
      // Add font-size for numbered lists
      styleEntities.push({
        style: `avv-numbered:has($IDENT$)::before {${fontSize}}`,
        custom: true
      })
    }
  }

  // All styles that are generating custom css
  const custom = styleEntities.filter((e) => e.custom)

  styleElement.innerHTML +=
    // Add a new line to separate the custom styles
    '\n' +
    // Add the custom styles
    custom.map((item) => item.style.replace('$IDENT$', innerIdent)).join('\n')

  // Find all numberings and update their font-size on the fly
  numberings.forEach((numbering) => {
    const line = descendantBlot(
      numbering,
      0,
      Infinity,
      isScopeExactLineBlot,
      false
    )
    if (line) {
      const lineStyle =
        line.attributesOptional['data-avv-style'].getOr(defaultStyleKey)
      if (lineStyle == styleName) {
        numbering.schedule(ScheduleType.UPDATE_NUMBERING_WIDTH, {
          blot: numbering
        })
      }
    }
  })
}

const createGlobalStyles = (store: StoreWithStyles, numberings: Numbered[], editorOptions: EditorOptions) => {
  // We need access to the global styles
  if ((store.hydratedData && store.isFieldHydrated('docx_settings')) || store.storeMode == StoreMode.NewData) {
    const styles = store.docxSettings.formats
    for (const styleKey in styles) {
      const style = styles[styleKey]
      const definition = style.definition as DeltaInsertAttributes
      const blots = createBlotsMockup(definition, editorOptions)
      const commands = new Commands()

      // Let editor create the styling entities but we will use them for global styles (by prepending the stylename)
      createStyleEntitiesForBlots(blots, commands)

      // Transform the styling entities into global styles
      transformStyleEntitiesIntoGlobalStyles(
        styleKey,
        commands.spawnBuffer as GenerateStyleEntity[],
        store.defaultStyle.key,
        numberings
      )
    }
  }
}

const createBlotsMockupFromElements = (
  editorOptions: EditorOptions,
  elements: HTMLElement[] = Array.from(
    document.querySelectorAll<HTMLElement>('.avv-container > *')
  ),
): Blot[] => {
  const blots = elements.map((el) => createBlotMockup(el, editorOptions))
  return blots.filter((blot): blot is Blot => blot !== null)
}

const createEditorStyles = (
  store: StoreWithStyles,
  contentElement: HTMLElement,
  numbereds: (Numbered | ListNum)[],
  editorOptions: EditorOptions
) => {
  const flatten = (arr: Blot[], result: Blot[] = []): Blot[] => {
    arr.forEach((blot) => {
      result.push(blot)
      if ('children' in blot) {
        flatten(blot.children as Blot[], result)
      }
    })
    return result
  }
  const { PX_TO_MM } = getMeasurementNode()
  const blotsMockup = flatten(
    createBlotsMockupFromElements(
      editorOptions,
      Array.from(contentElement.querySelectorAll('.avv-container > *'))
    )
  )
  const commands = new Commands()

  const scrollMockup = new Scroll(
    contentElement.querySelector('.avv-container') as HTMLElement,
    new Registry(),
    editorOptions,
    getStagesMockup()
  )
  scrollMockup.children = blotsMockup

  // Recalculate numberings for indentation
  const numberingSystem = updateNumberingSystem()
  numberingSystem[3](
    numbereds.filter((n) => +getNumberingMaskVersion(n) >= 2),
    new ScheduledBlot(numbereds),
    new SystemTime(1),
    new SystemOptions(editorOptions),
    new Registry()
  )

  const oldNumbering = updateOldNumberingSystem()
    oldNumbering[3](
      numbereds.filter((n) => +getNumberingMaskVersion(n) <= 1),
      new ScheduledBlot(numbereds),
      new SystemTime(1),
      commands,
      scrollMockup,
      new SystemOptions(getEditorOptionsMockup()),
      new CalcOptions(PX_TO_MM, () => { throw new Error('Not implemented') })
    )

  // Recalculate all styles
  const updateStyles = updateStylesSystem()
  updateStyles[3](
    new ScheduledBlot(scrollMockup.children),
    commands,
    new SystemOptions(editorOptions)
  )

  // We need access to the global styles
  if (store.hydratedData || store.storeMode == StoreMode.NewData) {
    // Generate line-heights
    generateLineHeights(store, commands, scrollMockup)
  }

  // Generate styles
  generateStylesSystem()[3](commands, {
    type: ScheduleType.ENTITIES,
    data: commands.spawnBuffer
  })
}

const getStyleParents = (
  store: StoreWithStyles,
  style: Backend.Models.TemplateVersion.Style
) => {
  const parents: string[] = []
  if (style && style.parent) {
    parents.push(style.parent)
    parents.push(
      ...(getStyleParents(
        store,
        store.docxSettings.formats[style.parent]
      ) as string[])
    )
  }
  return parents
    .map((p) => store.docxSettings.formats[p])
    .filter((p) => p != null)
}

const generateLineHeights = (
  store: StoreWithStyles,
  commands: Commands,
  scroll: Scroll
) => {
  const defaultStyle = store.defaultStyle
  if (!defaultStyle) return

  const lines = descendantBlots(scroll, 0, Infinity, isScopeExactLineBlot)
  lines.forEach((line) => {
    const lineHeightOpt = line.attributesOptional['data-avv-line-height']
    const lineRuleOpt = line.attributesOptional['data-docx-line-rule']
    if (lineHeightOpt.isPresent() && lineRuleOpt.isPresent()) {
      const lineRule = lineRuleOpt.get()
      const lineHeight = lineHeightOpt.get()

      if (lineRule === 'atLeast') {
        // Find parent style with line-height
        const lineStyleKey = line.attributesOptional['data-avv-style'].getOr(
          defaultStyle.key
        )
        const style = store.docxSettings.formats[lineStyleKey] ?? defaultStyle
        const parents = getStyleParents(store, style)
        const currentLineHeight =
          parents.find(
            (p) =>
              p.definition?.block?.['data-avv-line-height'] &&
              p.definition?.block?.['data-avv-line-height'] != lineHeight
          )?.definition?.block?.['data-avv-line-height'] ??
          defaultStyle.definition?.block?.['data-avv-line-height'] ??
          '1.15'

        commands.spawn(
          new GenerateStyleEntity(
            `p[data-avv-line-height="${lineHeight}"][data-docx-line-rule="${lineRule}"] { line-height: clamp(${lineHeight}mm, ${currentLineHeight}mm, 100mm) !important; }`,
            line
          )
        )
      }
    }
  })
}

const runUpdateStyles = async (
  store: StoreWithStyles,
  contentElement?: HTMLElement
) => {
  let numberings = EditorFactory.mainOptional.mapOr(
    (editor) => editor.query(Query<Numbered | ListNum>('numbered', 'listNum')),
    undefined
  )

  const editorOptions = await getEditorOptionsMockup()

  if (contentElement) {
    const numberingElements = Array.from(
      contentElement.querySelectorAll('avv-numbered, avv-list-num')
    )
    const numberingLikeBlots = numberingElements.map((el) => createBlotMockup(el as HTMLElement, editorOptions))
    // Filter out null values and cast to Numbered type
    numberings = numberingLikeBlots.filter((blot): blot is Numbered | ListNum =>
      blot !== null
    )
  }

  if (EditorFactory.mainOptional.isAbsent() || contentElement) {
    createEditorStyles(
      store,
      contentElement ?? EditorFactory.main.scroll.node,
      numberings!,
      editorOptions
    )
  }

  createGlobalStyles(store, numberings!, editorOptions)
}

export type StoreWithStyles =
  | DocumentStoreType
  | TemplateVersionStoreType
  | CustomClauseVariantStoreType

// Create global styles
// Create editor styles only if editor is absent
/**
 *
 * @param store
 * @param contentElement Pass this parameter only if editor is not present
 */
export const handleEditorStyles = (
  store: StoreWithStyles,
  contentElement?: HTMLElement
) => {

  // When any docx setting is changed, we need to update the styles
  if (store.hydrated) {
    onStorePropertiesHydrated(store, ['docx_settings'], () => {
      // Watch if template was changed to/from agreement only
      watch(
        () => store.docxSettings,
        () => {
          void runUpdateStyles(store, contentElement)
        },
        { deep: true, immediate: true }
      )
    })
  } else {
    void runUpdateStyles(store, contentElement)
  }
}
